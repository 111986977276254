import React from 'react';
import { SocialIcon } from 'react-social-icons';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hi! Welcome to my website!</h1>
        <h2>As you can see, it is very much under construction</h2>
        <h2>In the meanwhile check out my other links:</h2>
        <div id="social_div">
          <a href='https://github.com/AmitBokoDev' target="_blank" rel="noreferrer">
            <span class="social_span"><SocialIcon fgColor="white" network="github"  /> AmitBokoDev</span>
          </a>
          <a href='https://www.linkedin.com/in/amit-bokobza/' target="_blank" rel="noreferrer">
            <span class="social_span"><SocialIcon fgColor="white" network="linkedin" /> Amit Bokobza</span>
          </a>
          <a href='mailto:amit@bokobza.dev' target="_blank" rel="noreferrer">
            <span class="social_span"><SocialIcon fgColor="white" network="email" /> amit@bokobza.dev</span>
          </a>
        </div>
        <div id="footer">
          Made at 4am with VSCode, React, and Netlify
        </div>
      </header>
    </div>
  );
}

export default App;
